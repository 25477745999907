<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    >
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
        :idchecklist-ot="routeParams.idchecklist_ot"
        :idorden-trabajo-matsist="idsParaVolerAtras.idorden_trabajo_matsist"
        :idparte-trabajo-matsist="idsParaVolerAtras.idparte_trabajo_matsist"
        :idgrupo-checklist-ot="idsParaVolerAtras.idgrupo_checklist_ot"
        :idsubsis="idsParaVolerAtras.idsubsis"
      />
    </b10-toolbar>
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-file-gallery
        v-if="hasViewPerm(permissions.accionCorrectoraAdjunto.id)"
        class="pa-3"
        :images="ficherosGaleria"
        data-field="fichero.data"
        type-field="fichero.type"
        filename-field="fichero.nombre_fichero"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './AccionCorrectoraViewData'
import { CHECKLIST, TABLA } from '@/utils/consts'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'

export default {
  components: {
    VolverParteChecklistMenuItems,
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'accion_correctora_ot.descripcion', label: 'Descripción' },
        { name: 'accion_correctora_ot.observaciones', label: 'Observaciones' },
      ],
      toolbarOptions: {
        edit: {
          title: 'Modificar',
          visible: true,
          icon: 'edit'
        },
        delete: {
          title: 'Eliminar',
          visible: true,
          icon: 'delete'
        },
        addAdjunto: {
          title: 'Añadir adjunto',
          visible: true,
          icon: 'add'
        },
      },
      showingDialogs: {
        moreInfo: false,
      },
      ficherosGaleria: [],
      idsParaVolerAtras: {
        idorden_trabajo_matsist: null,
        idparte_trabajo_matsist: null,
        idgrupo_checklist_ot: null,
        idsubsis: null,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.accion_correctora_ot.idaccion_correctora_ot
        item.title = item.accion_correctora_ot.descripcion
        item.subtitle = item.accion_correctora_ot.descripcion_ampliada
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
    estadoChecklistPendiente () {
      return this.item.dataset.checklist_ot.estado === CHECKLIST.estados.pendiente
    }
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `Acción correctora "${this.item.dataset.accion_correctora_ot.descripcion}"`
      if (this.item.dataset.pregunta_checklist_ot.idpregunta_checklist_ot){
        const materialSistema = await this.$offline.preguntaChecklistOt.selectMaterialSistemaDePreguntaChecklistOt(
          this.item.dataset.pregunta_checklist_ot.idpregunta_checklist_ot
        )
        if (!!materialSistema)  {
          this.idsParaVolerAtras.idorden_trabajo_matsist = materialSistema.orden_trabajo_matsist.idorden_trabajo_matsist
          this.idsParaVolerAtras.idparte_trabajo_matsist = materialSistema.parte_trabajo_matsist.idparte_trabajo_matsist
          this.idsParaVolerAtras.idsubsis =  materialSistema.material_sistema.idsubsis
        }
      }
      this.idsParaVolerAtras.idgrupo_checklist_ot = this.item.dataset.grupo_checklist_ot.idgrupo_checklist_ot
      const datasetFicheros = await Data.selectFicheroAccionCorrectora(this, this.routeParams.idaccion_correctora_ot)
      this.ficherosGaleria = datasetFicheros
      await this.loadDetailTotals()
      this.toolbarOptions.edit.visible = this.estadoChecklistPendiente
      this.toolbarOptions.delete.visible = this.estadoChecklistPendiente
      this.toolbarOptions.addAdjunto.visible = this.estadoChecklistPendiente
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectAccionCorrectoraOt(this, this.routeParams.idaccion_correctora_ot)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      // ficheros
      if (this.hasViewPerm(this.permissions.accionCorrectoraAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'ficheros', 'Adjuntos', 'Fotografías y adjuntos de la acción correctora', 'attach'
        )
        const datasetFicheros = await Data.selectFicherosCount(this, this.routeParams.idaccion_correctora_ot)
        detailFicheros.badge = datasetFicheros.count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'ficheros') {
        this.$appRouter.push({
          name: 'offline__accion-correctora-fichero-list',
          params: {
            idtabla: TABLA.accion_correctora_ot.idtabla,
            id: this.routeParams.idaccion_correctora_ot,
            readonly: !this.estadoChecklistPendiente,
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.edit) {
        this.$appRouter.push({
          name: 'offline__accion-correctora-edit',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: this.routeParams.idchecklist_ot,
            idgrupo_checklist_ot: this.routeParams.idgrupo_checklist_ot,
            idpregunta_checklist_ot: this.routeParams.idpregunta_checklist_ot,
            idtanomalia_checklist_ot: this.routeParams.idtanomalia_checklist_ot,
            idaccion_correctora_ot: this.routeParams.idaccion_correctora_ot,
          },
        })
      } else if (option === this.toolbarOptions.delete) {
        this.deleteAccionCorrectora()
      } else if (option === this.toolbarOptions.addAdjunto) {
        this.$appRouter.push({
          name: 'offline__fichero-add',
          params: {
            idtabla: TABLA.accion_correctora_ot.idtabla,
            id: this.routeParams.idaccion_correctora_ot,
          }
        })
      }
    },
    async deleteAccionCorrectora () {
      // TODO: eliminar accion_ejec o artículo si lo metió
      const res = await this.$alert.showConfirm(`¿Deseas eliminar la acción correctora "${this.item.dataset.accion_correctora_ot.descripcion}"?`)
      if (res) {
        await this.$offline.accionCorrectoraOt.deleteSync(
          this.item.dataset.accion_correctora_ot.idaccion_correctora_ot
        )
        await this.$dirty.deleted(this.$dirty.ENTITIES.local.accionCorrectoraOt, this.routeParams.idaccion_correctora_ot)
        this.$appRouter.go(-1)
      }
    },
  },
}
</script>
